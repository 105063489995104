export default class RamphastosHTMLService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($q) {
        this.getHTMLWithInlinedResources = function() {
            var dom = $(document.documentElement).clone();
            var promises = [];

            // inline styles.
            dom.find('link[rel="stylesheet"]').each(function() {
                var defer = $q.defer();
                var linkElement = $(this);
                var cssPromises = [];
                var cssText = "";
                var urlDataMap = {};

                // We need to get the original sheet here because it's not loaded in the cloned element.
                var sheet = $('link[href="' + $(this).attr("href") + '"]')[0].sheet;
                var cssRules = sheet.cssRules;

                // Add all css rules to the text, and replace urls with actual data.
                var baseUrlExp = /(.*\/).*$/;
                var urlExp = /url\(("|')?([^"'\)]*)("|')?\)/ig;
                var matches;
                for (var r = 0; r < cssRules.length; r++) {
                    cssText += cssRules[r].cssText;

                    while ((matches = urlExp.exec(cssRules[r].cssText)) !== null) {
                        // Dummy function to create new closure.
                        (function() {
                            var url = matches[2];
                            var cssDefer = $q.defer();
                            toDataURL(baseUrlExp.exec(sheet.href)[1] + url,
                                function(data) {
                                    urlDataMap[url] = data;
                                    cssDefer.resolve();
                                });
                            cssPromises.push(cssDefer.promise);
                        })();
                    }
                }

                // Wait until all resources were loaded and then replace the link with the inline style.
                $q.all(cssPromises).then(function() {
                    $.each(urlDataMap,
                        function(url, data) {
                            cssText = cssText.replace(url, data);
                        });

                    var styleElement = document.createElement("style");
                    $(styleElement)
                        .attr("type", "text/css")
                        .text(cssText);
                    linkElement.replaceWith(styleElement);
                    defer.resolve();
                });

                promises.push(defer.promise)
            });


            // Convert all canvases to images and save the dataURL to be inserted in the src attribute later.
            // We cannot set the src attribute directly in the cloned DOM because chrome throws a cross origin error.
            var canvases = $.find("canvas");

            var base64Images = [];
            dom.find("canvas").replaceWith(function(index) {
                var canvas = canvases[index];
                // We use a custom convert function here, because WebGL clears its buffer per default and therefore
                // toDataURL will return a transparent image. A WebGL renderer can set this function to render and
                // return the correct image itself. Alternatively one could create the OpenGL context with
                // preserveDrawingBuffer set to true, but this bad for performance.
                var toDataUrl = canvas.ramphastosConvertToDataUrl;
                if (typeof toDataUrl === "function") {
                    var dataUrl = toDataUrl();
                } else {
                    dataUrl = canvas.toDataURL();
                }
                base64Images.push(dataUrl);

                return $(document.createElement("img"))
                    .attr("class", "canvas-to-image-dummy");
            });

            // Only grab the images for now, but the method would work with any resource.
            // It's however important not to grab the javascript files, since this will break the generated html
            // (because there's no connection to the server).
            dom.find('img[src]').each(function() {
                var defer = $q.defer();
                toDataURL($(this).attr("src"),
                    (function(d) {
                        $(this).attr("src", d);
                        defer.resolve();
                    }).bind(this));
                promises.push(defer.promise);
            });

            // The generated file has no access to the view-model and therefore we need to write out the values explicitly.
            dom.find('input').each(function() {
                $(this).attr("value", $(this).val());
            });

            return $q(function(resolve, reject) {
                $q.all(promises).then(function() {
                    // Prepend doctype and now insert the images dataURLs of the converted canvases.
                    var domString = "<!DOCTYPE html>\n" + dom[0].outerHTML;
                    var matchIndex = 0;
                    domString = domString.replace(/class="canvas-to-image-dummy"/g,
                        function() {
                            var ret = 'src="' + base64Images[matchIndex] + '"';
                            matchIndex++;
                            return ret;
                        });
                    resolve(domString);
                });
            });
        };

        function toDataURL(url, callback) {
            // check if it's already a dataURL
            if (url.indexOf("data:") !== -1) {
                var re = /.*(data:.*)/;
                callback(re.exec(url)[1]);
                return;
            }

            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }
    }
}
