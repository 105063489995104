import $ from 'jquery';

export default class RamphastosApplicationService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($rootScope,
                $q,
                $ocLazyLoad,
                $timeout,
                ramphastosDiffPatchService,
                ramphastosCyclicJsonService,
                ramphastosSuggestionService,
                ramphastosRoutingService,
                ramphastosLocaleService,
                ramphastosUrlLocalizeService) {

        var ramphastosAppModel = {
                RootViewModel: {
                    RamphastosPath: "root",
                    RamphastosType: "RamphastosViewModel",
                    View: {
                        Template: '<div></div>' //empty string is not enough
                    }
                },
                RoutingUrl: null,
                Locale: undefined
            },
            emptyMessageBoxViewModel = {
                RamphastosPath: "MessageBoxRoot",
                RamphastosType: "RamphastosViewModel",
                View: {
                    Template: "<div></div>" //empty string is not enough
                }
            },
        ramphScopeLink = {}, //Link RamphastosPath with scope: key is RamphastosPath, value is scope
        //note that this cache does contain the 'full' view-model and not the reduced version in the scope
        scopeRamphLink = {}, //Link scope with view-model: key is scopeId, value is view-model

        sharedObjects = {},

        privateUpdateViewModel,//function
        loadAdditionalFiles, //function
        loadAdditionalModules, //function

        contentDeliveryUrl,

        scopeJavaScriptExecutionQueue = {}; //cache for JavaScript function calls on the view-model

        var messageBoxViewModel = emptyMessageBoxViewModel;

        var nonYetExistentViewModelDeltas = {};

        // used to track currently pending updates. Key is RamphastosPath of view-model.
        var viewModelUpdatePromises = {};
        
        // stores all lazy loaded modules, for use in the inspector on clientside
        var lazyLoadedModules = {};

        //used to track hint objects with forFilter properties so that we can update the values after something changed on the view-model
        var forFilterHintObjectCache = {};
        var forFilterHintObjectCacheScopeLink = {};

        var debugEnabled = global.ramphastosEnableDebugInfoFromBoostrap;

        var preventApplicationModelCacheWriteback = false;

        /**
         * resolve a view-model hint object
         * @param {Object} viewModelHint The hint object
         * @param {Object} parentViewModel The parent view-model
         * @returns {Object} The viewModel
         */
        var resolveViewModelHint = function (viewModelHint, parentViewModel) {
            var viewModel;
            if (viewModelHint.Collection !== undefined &&
                    viewModelHint.Collection !== null) {
                viewModel = parentViewModel[viewModelHint.Collection][viewModelHint.Key]; //resolve real view-model with information in hint object    
            } else {
                var names = viewModelHint.ViewModelName.split(".");
                if (names.length > 0) {
                    viewModel = parentViewModel;
                    $.each(names, function (key, value) {
                        var indexSplit = value.split('[');
                        if (indexSplit.length === 2) {
                            var index = indexSplit[1].replace(']', '');
                            var collection = viewModel[indexSplit[0]];
                            viewModel = collection[index];
                        } else {
                            viewModel = viewModel[value];
                        }
                    });
                }
            }
            return viewModel;
        };




        this.getRamphastosPath = function(scope) {
            if (this.isRamphastosScope(scope)) {
                return scope.vm.RamphastosPath;
            } else {
                var parentRamScope = this.getParentRamphastosScope(scope).ramphastosChildScope;
                return parentRamScope.vm.RamphastosPath;
            }
        }.bind(this);

        this.debugInfoEnabled = function(newValue)
        {
            if (newValue === undefined) {
                return debugEnabled;
            } else {
                debugEnabled = newValue;
                return newValue;
            }
            
        };
     

        //TODO: increate performance by creating link list partialy allready on server side
        /**
         * Get the view-model. To learn what a "hint" object is do also have a look at fillScope funciton
         * of the ramphastosFillViewScopeService. The hint object can be seen as a reference to the real view-model
         * that is put into the vm scope (this is done to increase performance as we reduce the data in the scope).
         * The hint object is used when we have a collection of child view-models.
         * @param {Object} parentScope The parent scope
         * @param {Object} viewModelNameOrHint Either the property name of the view-model or the hint object as a json string
         * @returns {Object} The viewModel
         */
        this.getViewModelByName = function (parentScope, viewModelNameOrHint) {
            var viewModel, parentViewModel, viewModelHint, parentViewModelScope;
            //handle request for root view-model
            if (viewModelNameOrHint === "RootViewModel") {
                return ramphastosAppModel.RootViewModel;
            }
            //handle requirest for messageBox view-model
            if (messageBoxViewModel !== null && viewModelNameOrHint === messageBoxViewModel.RamphastosPath) {
                return messageBoxViewModel;
            }
            //for the moment we support both version "vm.subViewModel" and "subViewModel"
            viewModelNameOrHint = viewModelNameOrHint.replace(/^vm./, '');
           
            parentViewModel = scopeRamphLink[parentScope.$id]; //get the viewModel from the parent scope
            if (parentViewModel === undefined) {
                throw Error("parentViewModel was undefined");
            }
            try {
                //check if we have a hint object (used in collections of view-models)
                if(viewModelNameOrHint !== undefined 
                    && viewModelNameOrHint !== null 
                    && viewModelNameOrHint.length > 1
                    //A trim could be possible but would be a lot slower
                    //so we trust that the string starts with '{' in case its a JSON
                    && viewModelNameOrHint.charAt(0) === '{') {
                    viewModelHint = JSON.parse(viewModelNameOrHint);
                    }
            } catch (e) {
                //do nothing
            }
            //look if we have a hint object and try to handle that case
            if (viewModelHint !== undefined
                && viewModelHint !== null
                && viewModelHint.RamphastosType === "RamphastosViewModelHint") {
                viewModel = resolveViewModelHint(viewModelHint, parentViewModel);
                //TODO: fix this
                //while (viewModel.RamphastosType === "RamphastosViewModelHint") {
                //    parentViewModelScope = getParentViewModelScope(parentScope);
                //    parentViewModel = scopeRamphLink[parentViewModelScope.$id]; //get the viewModel from the parent scope
                //    viewModel = resolveViewModelHint(viewModel, parentViewModel);
                //}
            }
            else {
                var split = viewModelNameOrHint.split('.');
                viewModel = parentViewModel;
                $.each(split, function(key, value) {
                    if (viewModel[value] === undefined) {
                        var suggestion = ramphastosSuggestionService.getSuggestion(viewModel, value);
                        if (suggestion !== null) {
                            throw Error("Property '" + value + "' does not exist. Did you mean: '" + suggestion + "'?");
                        } else {
                            throw Error("Property '" + value + "' does not exist.");
                        }
                    }
                    viewModel = viewModel[value];
                });

                //viewModel = parentViewModel[viewModelNameOrHint]; //we have a normal name
                //TODO: fix this
                //while (viewModel.RamphastosType === "RamphastosViewModelHint") {
                //    parentViewModelScope = getParentViewModelScope(parentScope);
                //    parentViewModel = scopeRamphLink[parentViewModelScope.$id]; //get the viewModel from the parent scope
                //    viewModel = resolveViewModelHint(viewModel, parentViewModel);
                //}
            }
            return viewModel;
        };

        this.getParentRamphastosScope = function (scope) {
            var designatedParent;
            if (scope.vmName === "RootViewModel") { //TODO: refactor that we have 'root' here
                designatedParent = scope.$parent;
            } else if (scope.vmName === "MessageBoxRoot") {
                designatedParent = scope.$parent;
            }
            else {
                var currentParent = scope.$parent;
                while (designatedParent === undefined) {
                    if (this.isRamphastosScope(currentParent)) {
                        designatedParent = currentParent;
                    } else {
                        currentParent = currentParent.$parent;
                    }
                    if (currentParent === null) {
                        throw Error("Could not find parent ramphastos scope for scope with $id= " + scope.$id);
                    }
                }
            }
            return designatedParent;
        };

        this.isRamphastosScope = function(someScope) {
            if (scopeRamphLink[someScope.$id] !== undefined) {
                return true;
            }
            return false;
        }.bind(this);

        this.getRamphastosPathForRamphastosScope = function (someScope) {
            if (scopeRamphLink[someScope.$id] !== undefined
                && scopeRamphLink[someScope.$id] !== null) {
                return scopeRamphLink[someScope.$id].RamphastosPath;
            }
            return null;
        };

        /**
        * Get the AngularJS scope for a given view-model via it's RamphastosPath
        * @param {string} ramphastosPath The ramphastosPath of a view-model
        * @param {boolean} throwErrorWhenScopeNotFound Whether or not to throw an exception in case the scope could not be found
        * @returns {Object} The angularJS scope
        */
        this.getScope = function(ramphastosPath, throwErrorWhenScopeNotFound) {
            throwErrorWhenScopeNotFound = typeof throwErrorWhenScopeNotFound !== 'undefined' ? throwErrorWhenScopeNotFound : true;
            var scope = ramphScopeLink[ramphastosPath];
            if (scope === undefined && throwErrorWhenScopeNotFound) {
               throw Error("Error: AngularJS scope for given id does not exits! RamphastosPath was: " + ramphastosPath);
            }
            return scope;
        };

        this.linkRamphScopePair = function (viewModel, scope) {
            //start - consitency check
            //var checkConsitency = function (myViewModel) {
            //    $.each(myViewModel, function (key, value) {
            //        if (value.RamphastosType === "RamphastosViewModelHint") {
            //            throw Error("RamphastosViewModelHint object must not be added to the cache!");
            //        }
            //        if (value.RamphastosType === "RamphstosViewModel") {
            //            checkConsitency(value);
            //        }
            //    });
            //}
            //checkConsitency(viewModel);
            //end - consitency check

            //console.info("linking: " + viewModel.RamphastosPath + " - " + scope.$id);

            //TODO: check with a unit test that this behaviour here cannot happen and then remove the test here
            if (ramphScopeLink[viewModel.RamphastosPath] !== undefined) {
                //we allready have a link.
                var otherLinkedScope = ramphScopeLink[viewModel.RamphastosPath];
                if (scope !== otherLinkedScope) {
                    console.warn("Linking view-model to multiple scopes! Make sure a view-model is only linked to one scope!" +
                        " The error appeared with view-model path:" + viewModel.RamphastosPath);
                }
            }
            ramphScopeLink[viewModel.RamphastosPath] = scope;
            scopeRamphLink[scope.$id] = viewModel;
        };

        this.unlinkRamphScopePair = function (scope) {
            var viewModel = scopeRamphLink[scope.$id];
            if (viewModel === undefined || viewModel === null) return;

            //console.info("unlinking: " + scopeRamphLink[scope.$id].RamphastosPath + " - " + scope.$id);

            delete scopeRamphLink[scope.$id];
            delete ramphScopeLink[viewModel.RamphastosPath];
        };

        this.getFullScopeViewModel = function (scope) {
            if (scopeRamphLink[scope.$id] !== undefined) {
                return scopeRamphLink[scope.$id];
            } else {
                throw Error("Cannot update a view-model/scope pair that does not exist!");
            }
        };

        var initRouting = function (applicationModel) {
            ramphastosRoutingService.initRouting(applicationModel.RoutingUrl);
        };

        var buildResourceLink = function (file) {
            if (contentDeliveryUrl !== undefined && contentDeliveryUrl !== null) {
                return contentDeliveryUrl + "ramphastos/File/" + file.Assembly + "/" + file.ResourceNamespace + "." + file.File;
            } else {
                //Structure: /ramphastos/File/{ASSEMBLY}/{FILENAMESPACE}
                return "ramphastos/File/" + file.Assembly + "/" + file.ResourceNamespace + "." + file.File;
            }

        };

        this.buildResourceLink = buildResourceLink; //make this function available for other

        /**
        * Init and run the application
        * @param {Object} applicationModel The application-model
        * @param {Array<Object>} angularJsModules A list of modules
        * @param {string} newContentDeliverUrl Url for content delivery (if applicable)
        */
        this.run = function (applicationModel, angularJsModules, newContentDeliverUrl) {
            var currentLocale = ramphastosAppModel.Locale;
            contentDeliveryUrl = newContentDeliverUrl;
            //the check for undefined is required, otherwise we can end up in an infinite loop during startup.
            if (currentLocale !== undefined) {
                if (applicationModel.Locale !== currentLocale) {
                    ramphastosLocaleService.updateLocale(applicationModel.Locale);
                }
            } else if (applicationModel.Locale !== undefined) {
                ramphastosLocaleService.setLocale(applicationModel.Locale);
            } 

            var ngModuleLoadPromise;

            //register special debug function to run again from development console
            window.ramphastosRestart = function() {
                this.run(applicationModel);
            }.bind(this);
            if (debugEnabled) {
                console.log('Running ramphastos application');
            }
            if (applicationModel.RamphastosType !== 'RamphastosWindowModel') {
                console.warn("application model is not of type 'RamphastosWindowModel'");
            }
            if (applicationModel.RootViewModel === null) {
                console.error('RootViewModel was null! Aborting...');
                return;
            }
            if (applicationModel.RootViewModel.RamphastosType !== 'RamphastosViewModel') {
                console.warn("application base model is not of type 'RamphastosViewModel'");
            }

            ramphastosAppModel = applicationModel;

            initRouting(applicationModel);

            //build link list (could also happen on the server side)
            loadAdditionalFiles(applicationModel.AdditionalFiles);

            if (angularJsModules !== undefined && angularJsModules.length > 0) {
                ngModuleLoadPromise = loadAdditionalModules(angularJsModules);
                ngModuleLoadPromise.then(function success() {
                    preventApplicationModelCacheWriteback = true;
                    privateUpdateViewModel(applicationModel.RootViewModel);
                    $rootScope.$emit('appModelChanged', applicationModel);
                    preventApplicationModelCacheWriteback = false;
                }, function error(err) {
                    console.error('Error loading additional modules: ', err.stack);
                });
            } else {
                preventApplicationModelCacheWriteback = true;
                privateUpdateViewModel(applicationModel.RootViewModel);
                $rootScope.$emit('appModelChanged', applicationModel);
                preventApplicationModelCacheWriteback = false;
            }

            //load json literal files if there are any
            angular.forEach(applicationModel.JsonLiterals, function (value, index) {
                ramphastosLocaleService.loadJsonLiteralResources(buildResourceLink(value));
            });
        }.bind(this);

        //Description of the application model (that is used to load it from the server)
        this.AppModelDescription = function (applicationModelAssembly, applicationModel, applicationModelParameters) {
            this.applicationModelAssembly = applicationModelAssembly;
            this.applicationModel = applicationModel;
            this.applicationModelParameters = applicationModelParameters;
        }
        var appModelDescriptionConstructor = this.AppModelDescription;

        this.buildAppModelDescription = function (path, search) {
            var splitted = path.split('/');
            var assemblyName = splitted[1];
            var appModel = splitted[2];
            return new appModelDescriptionConstructor(assemblyName, appModel, search);
        };

        /**
         * Lazy-load additional files
         * @param {Array<string>} files A list of files to load
         */
        loadAdditionalFiles = function (files) {
            angular.forEach(files, function (value, index) {
                if (value.RamphastosType === "CssFile") {
                    $("<link/>", {
                        rel: "stylesheet",
                        type: "text/css",
                        href: buildResourceLink(value)
                    }).appendTo("head");
                } else if (value.RamphastosType === "JsFile") {
                    var url = buildResourceLink(value);
                    $.ajax({
                        //TODO: this makes only seens as soon as it is allowed to add external files as resources
                        //if cross-domain requests are allowed is defined on the application-model 
                        crossDomain: !ramphastosAppModel.ForbidCrossDomainRequest, 
                        dataType: "script",
                        url: url,
                        success: function() {
                            if (value.OnLoadJs !== null) {
                                eval(value.OnLoadJs);
                            }
                        },
                        error: function() {
                            console.error("error loading script: " + url);
                        }
                    });
                }
            });
        };

     

        /**
         * Lazy-load additional modules with ocLazyLoad
         * @param {Array<Object>} modules A list of modules to be loaded (C# class: RamphastosUi.Core.Files.AngularJsModule)
         * @returns {Object} promise
         */
        loadAdditionalModules = function (modules) {
            var promises = [];
            var deferred = $q.defer();
            angular.forEach(modules, function (value, key) {
                var fileList = [], 
                    innerDeffered = $q.defer();
                promises.push(innerDeffered.promise);

                $.each(value.ModuleFiles, function (key2, value2) {
                    //since lazyloaded urls are not running through the ramphastosInterceptor they have to be localized separately
                    var url = ramphastosUrlLocalizeService.localize(buildResourceLink(value2));
                    fileList.push(url);
                });
                if (debugEnabled) {
                    console.log('Lazy-loading module:' + value.Name);
                }
                $ocLazyLoad.load({
                    name: value.Name,
                    files: fileList,
                    serie: true
                }).then(function () {
                    innerDeffered.resolve();
                    lazyLoadedModules[value.Name] = value.ModuleFiles;
                }, function (e) {
                    console.error(e);
                    innerDeffered.resolve(); //anyway try to continue running the application
                });
            });
            $q.all(promises).then(function success() {
                deferred.resolve();
            }, function error(err) {
                deferred.reject(err);
            });
            return deferred.promise;
        };

       

        this.error = function (msg) {
            angular.element('body').html('Fatal error: ' + msg);
            console.error(msg);
        };

        var ensureCorrectViewModelPropertyName = function (key) {
            if (key === 'root') {
                key = 'RootViewModel';
            }
            return key;
        };

        var getChildViewModels = function (viewModel, viewModels) {
            if (viewModels === undefined) {
                viewModels = [];
            }
            //Ensure we have no cyclic loop
            if (viewModels.indexOf(viewModel) >= 0) {
                return viewModels;
            }
            for (var property in viewModel) {
                if (viewModel.hasOwnProperty(property)) {
                    if (viewModel[property] !== null
                        && viewModel[property].RamphastosType !== undefined
                        && viewModel[property].RamphastosType !== null
                        && viewModel[property].RamphastosType === "RamphastosViewModel") {
                        getChildViewModels(viewModel[property], viewModels);
                        viewModels.push(viewModel[property]);
                    }
                }
            }
            return viewModels;
        };
        
        this.getLazyLoadedModules = () => lazyLoadedModules;


        /*******************************************************************************************
         * Handling changed events of not yet loaded view-models
         * 
         * (note that the "_" char is used as a security measure in case the hash is of int type,
         *  if not present we would get endless iterations because JavaScript would fill all indexes
         *  lower thatn the hash with undefined.)
         * 
         *******************************************************************************************/

        /* Cache a delta for a view-model. This is called in the case we don't yet have a view-model
         * TODO: cover by unit-test
         * @param string ramphastosPath 
         * @param {} deltaJson 
         * @returns void
         */
        var cachePropertyChangedDeltas = function (ramphastosPath, viewModelObjectHash, deltaJson) {
            if (nonYetExistentViewModelDeltas[ramphastosPath] === undefined) {
                nonYetExistentViewModelDeltas[ramphastosPath] = [];
            }
            //We want an associative array, so wen enforce a string with a _ underscore character
            if (nonYetExistentViewModelDeltas[ramphastosPath]["_" + viewModelObjectHash] === undefined) {
                nonYetExistentViewModelDeltas[ramphastosPath]["_" + viewModelObjectHash] = [];
            }
            nonYetExistentViewModelDeltas[ramphastosPath]["_" + viewModelObjectHash].push(deltaJson);
        };

        /* Apply cached property changed deltas for a given ramphastos-path
         * TODO: cover by unit-test
         * @param {} ramphastosPath 
         * @returns void noting
         */
        var executeCachedPropertyChangedDeltas = function (viewModel) {
            if (nonYetExistentViewModelDeltas[viewModel.RamphastosPath] !== undefined) {
                if (nonYetExistentViewModelDeltas[viewModel.RamphastosPath]["_" + viewModel.Hash] !== undefined) {
                    nonYetExistentViewModelDeltas[viewModel.RamphastosPath]["_" + viewModel.Hash].forEach(function (item) {
                        this.updateViewModelProperties(viewModel.RamphastosPath, viewModel.Hash, item);
                    }.bind(this));
                }
                //note: non executed deltas for other hash get delete automatically
                delete nonYetExistentViewModelDeltas[viewModel.RamphastosPath];
            }
        }.bind(this);

        /* Apply cached properties for view-model and all childs
        * TODO: cover by unit-test
        * @param {} viewModel 
        * @returns void
        */
        var executeCachedPropertyChangedDeltasForViewModel = function (viewModel) {
            //execute potential caching from the root view-model
            executeCachedPropertyChangedDeltas(viewModel);
            //we have to get all child view-model
            var childViewModels = getChildViewModels(viewModel);
            childViewModels.forEach(function (item) {
                try {
                    executeCachedPropertyChangedDeltas(item);
                } catch (e) {
                    console.error("Failed to execute cached deltas for view-model '" + item.RamphastosPath + "'");
                    //Trigger error event (ComService should trigger a resync when receiving the event).
                    $rootScope.$emit('propertyUpdateFailed', item.RamphastosPath);
                }
                
            });
        };

        /*******************************************************************************************/

        /* These two functions are used to try to restore the focused element in case the dom changes and the focus is lost.
        * It's a bit of a hack and only works on elements with an unique id and can cause the element to flash weirdly.
        */
        var getFocusedElement = function() {
            var elem = document.activeElement;
            if (elem && elem !== document.body) {
                return elem;
            }
            return null;
        };

        var restoreFocusedElement = function(elem) {
            if (elem === null || elem === document.activeElement) {
                return;
            }
            // We lost the original focus, try to restore.
            var newElem = document.getElementById(elem.id);
            if (newElem) {
                newElem.focus();
            }
        };

        this.getViewModelFromAppModelCache = function (ramphastosPath, expectedHash) {
            if (ramphastosPath === undefined) {
                console.error("ramphastosPath was undefined.");
                return null;
            }
            //get properties and array indices
            var regex = new RegExp("[/?]");
            var keyArray = ramphastosPath.split(regex);

            var lastKey = keyArray.pop();
            lastKey = ensureCorrectViewModelPropertyName(lastKey);
            
            var viewModel = ramphastosAppModel;
            $.each(keyArray, function (index, element) {
                element = ensureCorrectViewModelPropertyName(element);
                viewModel = viewModel[element];
                if (viewModel === undefined) {
                    viewModel = undefined;
                    return false;
                }
                return true;
            });
            if (viewModel === undefined) {
                return undefined;
            }
            viewModel = viewModel[lastKey];

            // Exectue additional consitency check if expectedHash parameter has been passed
            if (expectedHash !== undefined && viewModel.Hash !== expectedHash) {
                throw Error("Hash of cached view-model was not what we expected. Expected: " + expectedHash + " but found: " + viewModel.Hash);
            }

            return viewModel;
        };

      


        /* Update a view-model instance
         * @param {} viewModel 
         * @returns void
         */
        this.updateViewModel = function (viewModel) {
            var ramphastosPath = viewModel.RamphastosPath;
            // --------------------------------------------------------------------------------
            // first we want to see if an update is running for the very same view-model path
            if (viewModelUpdatePromises[viewModel.RamphastosPath] !== undefined) {
                viewModelUpdatePromises[viewModel.RamphastosPath].then(function () {
                    this.updateViewModel(viewModel);
                    return;
                }.bind(this));
                // update is running -> stop. Update will be executed after promise is resolved
                return;
            }
            try {
                // --------------------------------------------------------------------------------
                // now we want to see if an update is running for a parent view-model.
                var promiseFromViewDirective = null;
                var parentIsUpdating = false;
                $.each(viewModelUpdatePromises, function (key) {
                    if (viewModel.RamphastosPath.startsWith(key)) {
                        //an update is running for a parent
                        var deferred = undefined;
                        if (viewModel.RamphastosPath !== key) {
                            //create a promise for this as well
                            deferred = $q.defer();
                            viewModelUpdatePromises[viewModel.RamphastosPath] = deferred.promise;
                        }
                        viewModelUpdatePromises[key].then(function () {
                            this.updateViewModel(viewModel);
                            if (deferred !== undefined) {
                                delete viewModelUpdatePromises[viewModel.RamphastosPath];
                                deferred.resolve();
                            }
                        }.bind(this));
                        parentIsUpdating = true;
                        return false; // break
                    }
                }.bind(this));
                if (parentIsUpdating) {
                    return; //Stop... update will happen when promise of running parent update is resolved
                }
                // --------------------------------------------------------------------------------
                // actual update start here
                var focusedElement = getFocusedElement(),
                    affectedScope = ramphScopeLink[ramphastosPath];
                if (affectedScope !== undefined && affectedScope !== null) {
                    // we really have a scope for this case
                    promiseFromViewDirective = affectedScope.viewModelChanged(viewModel);
                }
                if (promiseFromViewDirective !== undefined && promiseFromViewDirective !== null) {
                    //we want to know we are actually updating this viewmodel
                    viewModelUpdatePromises[viewModel.RamphastosPath] = promiseFromViewDirective;
                    promiseFromViewDirective.then(function () {
                        delete viewModelUpdatePromises[viewModel.RamphastosPath];
                        this.updateAppModelCache(viewModel); //otherwise the following call will not work
                        executeCachedPropertyChangedDeltasForViewModel(viewModel); //TODO: cover by unit-test
                        this.updateAppModelCache(viewModel);
                    }.bind(this),
                        function() {
                            // we also have to remove the promise if the update failed
                            delete viewModelUpdatePromises[viewModel.RamphastosPath];
                        }

                    );
                } else {
                    this.updateAppModelCache(viewModel); //otherwise the following call will not work
                    executeCachedPropertyChangedDeltasForViewModel(viewModel);  //TODO: cover by unit-test
                    this.updateAppModelCache(viewModel);
                }
                $rootScope.$emit('viewModelChanged', viewModel); //broadcast change
                setTimeout(function() { restoreFocusedElement(focusedElement) }, 10);
                return promiseFromViewDirective;
            } catch (e) {
                console.error("Error while trying to update view-model.", e);
                return null;
            }
        }.bind(this);
        privateUpdateViewModel = this.updateViewModel; //private reference

        /**
        * Update app-model cache (instance of app-model in this service):
        * Here we assign a new instance of the view-model
        * @param {object} newViewModel The view-model to be cached.
        */
        this.updateAppModelCache = function (newViewModel) {
            try {
                var ramphastosPath = newViewModel.RamphastosPath;

                // get properties and array indices
                var regex = new RegExp("[/?]");
                var keyArray = ramphastosPath.split(regex);

                var lastKey = keyArray
                    .pop(); //remove last index so we can update the object itself rather than the copy

                lastKey = ensureCorrectViewModelPropertyName(lastKey);

                var viewModel = ramphastosAppModel;
                keyArray.forEach(function(key) {
                    key = ensureCorrectViewModelPropertyName(key);
                    viewModel = viewModel[key];
                });

                viewModel[lastKey] = newViewModel; //we found the viewModel to the ramphastosPath, update it.    
            } catch (e) {
                console.error("error while updating app-model cache", e);
            }            
        };

        /**
        * update a ViewModeProperty with an array of deltas
        * @param {string} ramphastosPath The path of the view-model
        * @param {string} viewModelObjectHash Sync hash value
        * @param {string} deltaJson JSON of an array of delta objects
        */
        this.updateViewModelProperties = function (ramphastosPath, viewModelObjectHash, deltaJson) {
            // --------------------------------------------------------------------------------
            // first we want to see if an update is running for the very same view-model path
            if (viewModelUpdatePromises[ramphastosPath] !== undefined) {
                viewModelUpdatePromises[ramphastosPath].then(function () {
                    this.updateViewModelProperties(ramphastosPath, viewModelObjectHash, deltaJson);
                    return;
                }.bind(this));
                return;
            }
            // --------------------------------------------------------------------------------
            // update the ui
            try {
                var focusedElement = getFocusedElement();
                var affectedScope = ramphScopeLink[ramphastosPath];
                // Step 1 - Get version from app-model cache
                var viewModelFull = this.getViewModelFromAppModelCache(ramphastosPath);
                if (affectedScope !== undefined
                    && affectedScope !== null
                    && ((viewModelFull !== null && viewModelFull !== undefined && viewModelFull.Hash === viewModelObjectHash)
                    || (viewModelFull === undefined || viewModelFull === null))) {
                    if (affectedScope.ramphastosChildScope === undefined) {
                        throw Error("ramphastosChildScope was undefined on scope.");
                    }
                    // Step 2 a) - Apply delta to scope
                    affectedScope.updateViewModelProperties(deltaJson); //we give a delta
                }else {
                   
                    if (viewModelFull !== undefined &&
                        viewModelFull !== null &&
                        viewModelFull.Hash === viewModelObjectHash) {
                        //Step 2 b) - Apply delta to cached instance 
                        // (if view-model exists and update is realy meant for this instance (checked with hash))
                        var deltaArray = ramphastosCyclicJsonService.fromCJSON(deltaJson);
                        ramphastosDiffPatchService.patchArray(viewModelFull, deltaArray);
                        //Step 3 - Update app-model cache
                        this.updateAppModelCache(viewModelFull);
                    } else {
                        //We have an update for a view-model we don't even have yet
                        //Step 2 c) cache the update, it will be applied later
                        cachePropertyChangedDeltas(ramphastosPath, viewModelObjectHash, deltaJson);
                    }
                }
                $timeout(function () { restoreFocusedElement(focusedElement) }, 0, false);
            } catch (updateError) {
                console.warn("Error while updating view-model properties. Resync will be triggered.", updateError.stack);
                // Trigger error event (ComService should trigger a resync when receiving the event).
                $rootScope.$emit('propertyUpdateFailed', ramphastosPath);
                //TODO: write log message to server such it ends up in the log-file
                return;
            }
        }.bind(this);

        //If sharedObject == null id is removed from the storage.
        this.updateSharedObject = function(id, sharedObject){
            if(sharedObject === null){
                delete sharedObjects[id];
                return;
            }
            //If an object with this id was already was added update the instance instead of assign it a new one.
            //This way the Value property will automatically be update everywhere an instance of the sharedObject is used.
            if(sharedObjects.hasOwnProperty(id)){
                sharedObjects[id].Value = sharedObject.Value;
            } else {
                //Not only add value but also the RamphastosType here, so the propertyChangedService doesn't listen to it.
                sharedObjects[id] = { 
                    RamphastosType: "RamphastosSharedObject",
                    Value: sharedObject.Value 
                };
            }
            $rootScope.$apply();
        }.bind(this);

        this.getSharedObject = function(id){
            return sharedObjects[id];
        }.bind(this);

        var fillTree = function (tree, viewModel, visited) {
            // Used to prevent endless recursion in case of cyclic references.
            if(!visited){
                visited = new Set();
            }
            visited.add(viewModel);
            
            $.each(viewModel, function (key, value) {
                if (value === null || value === undefined || visited.has(value)) return true;  //=continue
                if(value.constructor === Object)
                {
                    //check if is view-model
                    if (value.RamphastosType === "RamphastosViewModel" || value.RamphastosType === "RamphastosObject") {
                        tree[key] = {};
                        fillTree(tree[key], value);
                    }
                } 
                else if (value.constructor === Array){
                    if (value.length > 0) {
                        if (value[0] === null) {
                            throw Error("Tree element was null. This was not expeceted");
                        }
                        if (value[0].RamphastosType === "RamphastosViewModel" || value[0].RamphastosType === "RamphastosObject")
                        {
                            tree[key] = {};
                            fillTree(tree[key], value);
                        }
                    }
                }
                else if (key === "RamphastosPath") {
                    tree[key] = value;
                }
            });
        };


        /**
         * Get the RootViewModel 
         * @param {string} ramphastosPath The ramphastosPath (optional)
         * @returns {Object} a tree of view-models with properties
         */
        this.getViewModelTree = function (ramphastosPath /*optional*/) {
            var tree = {};
            var startingViewModel;
            if (ramphastosPath === undefined) {
                startingViewModel = ramphastosAppModel.RootViewModel;
            } else {
                throw Error("not implemented"); //TODO: implement
            }
            fillTree(tree, startingViewModel);
            return tree;
        };

        /**
        * Remove calls that are older than 1 second
        * @param {Object} cacheObj The cahce object
        */
        var cleanUpJavaScriptVmQueueByTimestamp = function (cacheObj) {
            var now;
            if(cacheObj.length>0)
            {
                now = Date.now(); 
                for (var i = 0; i < cacheObj.length; ++i) {
                    if ((now - cacheObj[i].timestamp) > 1000) {
                        cacheObj.splice(i--, 1);
                    }
                }  
            }
        };

        /**
        * Store calls to JavaScript in a Queue
        * @param {string} ramphastosPath The path of the view-model
        * @param {string} fname The name of the function to execute
        * @param {Array<Object>} parameters The parameters for the function
        */
        var queueVmJavaScriptCall = function (ramphastosPath, fname, parameters) {
            var cacheObj = scopeJavaScriptExecutionQueue[ramphastosPath];
            if (cacheObj === undefined || cacheObj === null) {
                cacheObj = [];
                scopeJavaScriptExecutionQueue[ramphastosPath] = cacheObj;
            } else {
                cleanUpJavaScriptVmQueueByTimestamp(cacheObj); //remove potential calls that are too old
            }
            cacheObj.push({
                'ramphastosPath': ramphastosPath,
                'fname': fname,
                'parameters': parameters,
                'timestamp': Date.now()
            });
        };

        /**
         * execute a JavaScript function on the view-model with the corresponding ramphastosPath with the given arguments
         * @param {string} ramphastosPath The path of the view-model
         * @param {string} fname The name of the function to execute
         * @param {Array<Object>} parameters The parameters for the function
         */
        this.call = function (ramphastosPath, fname, parameters) {
            //TODO: Think about what should be done if there is no scope, maybe caching will cause problems (for many calls)
            var outerScope = this.getScope(ramphastosPath, false);
            var doCache = false;
            if (outerScope !== undefined  && outerScope !== null) {
                var scope = outerScope.ramphastosChildScope;
                if (scope !== undefined && scope !== null) {
                    //we can savely execute the call
                    if (scope.vm === undefined) {
                        throw Error("Could not find view-model on angular scope");
                    }
                    else if (scope.vm[fname] === undefined) {
                        console.error("Could not find function '" + fname + "' on view-model " + scope.vm.RamphastosPath);
                        return;
                    }
                    try {
                        scope.vm[fname].apply(this, parameters);
                    } catch (ex) {
                        console.error("Exception while trying to call funciton: " + fname + ".", ex.stack);
                    }
                } else {
                    doCache = true;
                }
            } else {
                doCache = true;
            }
            if (doCache) {
                //we have again to cache the execution until the scope has been created
                queueVmJavaScriptCall(ramphastosPath, fname, parameters);
            }
        };

        this.executeQueuedVmJavaScriptFunctionCalls = function (ramphastosPath) {
            var cacheObj = scopeJavaScriptExecutionQueue[ramphastosPath];
            var context = this;
            if (cacheObj !== undefined && cacheObj !== null) {
                cleanUpJavaScriptVmQueueByTimestamp(cacheObj); //make sure we remove old waiting calls
                $.each(cacheObj, function(key, value) {
                    context.call(value.ramphastosPath, value.fname, value.parameters);
                });
            }
            delete scopeJavaScriptExecutionQueue[ramphastosPath];
        };

        //code from https://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object?page=1&tab=votes#tab-top
        //Returns true if it is a DOM node
        var isNode = function(o) {
            return (
                typeof Node === "object"
                    ? o instanceof Node
                    : o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
            );
        };

        //code from https://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object?page=1&tab=votes#tab-top
        //Returns true if it is a DOM element    
        var isElement = function(o) {
            return (
                typeof HTMLElement === "object"
                    ? o instanceof HTMLElement
                    : //DOM2
                    o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
            );
        };

        var dirtyCopyItem = function (item, destination, visited) {
            if (visited.has(item)) {
                return;
            }
            visited.add(item);
            if (destination === undefined) {
                throw Error("destination must not undefined");
            }
            $.each(item,
                function(key, value) {
                    if (key === "RamphastosType" || key === "RamphastosPath" || isElement(item[key]) || isNode(item[key])) {
                        return true; //continue
                    }
                    try {
                        if (value !== null && typeof value === 'object') {
                            if (destination[key] === undefined || destination[key] === null) {
                                destination[key] = value;
                            } else {
                                if (value.RamphastosType == "RamphastosViewModel") {
                                    return true;
                                }
                                dirtyCopyItem(item[key], destination[key], visited);
                            }
                            return true; //continue
                        }
                        destination[key] = value;
                    } catch (e) {
                        //There are some html readonly properties which are not detected by "isElement(item[key]) || isNode(item[key])" e.g. the top property
                        // since they are still readonly properties they throw exceptions when you try to write on them
                        return true;
                    }
                });
        };

        this.dirtyCopy = function (viewModelFromScope, viewModelToCopyTo) {
            dirtyCopyItem(viewModelFromScope, viewModelToCopyTo, new Set());
        };

        //be able to update routing
        this.updateRoutingUrl = function(routingUrl) {
            ramphastosAppModel.RoutingUrl = routingUrl;
            return ramphastosRoutingService.processRouteChangeFromServer(routingUrl);
        };

        this.getCurrentRoute = function() {
            return ramphastosAppModel.RoutingUrl;
        };

        this.getContentDeliveryUrl = function() {
            return contentDeliveryUrl;
        };

        this.addHintObjectWithForFiltering = function(hintObj, hintObjScope) {
            if (hintObj === undefined || hintObj === null || hintObj.RamphastosPath === undefined) {
                return;
            }
            forFilterHintObjectCache[hintObj.RamphastosPath] = hintObj;
            forFilterHintObjectCacheScopeLink[hintObj.RamphastosPath] = hintObjScope;
        };

        this.updateRelevantHintObjectIfRequired = function (ramphastosPath, viewModel) {
            // would be nice to work with diffing here but this would take a lot more work.
            if (forFilterHintObjectCache[ramphastosPath] !== undefined) {
                //we have a hint object corresponding to this view-model
                var hintObj = forFilterHintObjectCache[ramphastosPath];
                $.each(hintObj,
                    function(key, value) {
                        if (key === 'RamphastosType') return true; //=continue
                        if (key === 'RamphastosPath') return true; //=continue
                        if (key === 'Key') return true; //=continue
                        if (key === 'Collection') return true; //=continue
                        if (viewModel[key] !== undefined) {
                            hintObj[key] = viewModel[key];
                        }
                    });
                var hintObjScope = forFilterHintObjectCacheScopeLink[ramphastosPath];
                if (hintObjScope !== undefined) {
                    $timeout(function () {
                            scope.ramphastosChildScope.$digest();
                        },
                        0,
                        false);
                }

            }
        };

        this.removeHintObjectForFilterFromCache = function(ramphastosPath) {
            if (forFilterHintObjectCache[ramphastosPath] !== undefined) {
                delete forFilterHintObjectCache[ramphastosPath];
            }
            if (forFilterHintObjectCacheScopeLink[ramphastosPath] !== undefined) {
                delete forFilterHintObjectCache[ramphastosPath];
            }
        };

        this.getPreventApplicationModelCacheWriteback = function () {
            return preventApplicationModelCacheWriteback;
        }
    }
}
